<template>
  <div class="wrap">
    <!-- 新闻列表 内容 -->
    <div class="wrapsCen">
      <!-- 左侧盒子 -->
      <div class="wrapsCenL">
        <!-- 顶部面包屑 -->
        <el-breadcrumb separator-class="el-icon-arrow-right" class="wrapsTitle">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>培训资讯</el-breadcrumb-item>
          <el-breadcrumb-item class="title">
            {{ user.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 内容 -->
        <div class="wrapsCenLs">
          <div class="title">
            {{ user.title }}
          </div>
          <div class="laiyuan">
            <!-- <div>来源：{{ user.source }}</div>
            <div>作者：{{ user.author }}</div> -->

            <div class="time">{{ user.addTime }}</div>
          </div>
          <!-- <img :src="user.img"  /> -->
          <div class="rice">
            <div v-html="ImgSizeLimit(user.content)"></div>
          </div>
        </div>
        <!-- 上一篇、下一篇 -->
        <!--  <div class="wrapsCenLBtm">
          <p class="nextText">上一篇： <router-link :to="'/newsxq?id=' + useras.id">{{usera}}</router-link></p>
          <p class="nextText">下一篇： <router-link :to="'/newsxq?id=' + userbs.id">{{userb}}</router-link></p>
        </div> -->
      </div>
      <!-- 右侧盒子 -->
      <div class="wrapsCenR">
        <!-- 模块1 -->
        <div class="wrapsCenRs">
          <div
            class="dis_flex_between"
            style="display: flex; flex-direction: column"
          >
            <p class="title">相关资讯</p>
            <img class="icon" src="@/assets/img/Home/tips.png" />
            <!-- <span @click="goNextRep('/allnews')">查看更多&nbsp;></span> -->
          </div>
          <div
            class="elp flexB"
            v-for="(item, index) in newsList"
            :key="index"
            @click="allnewxq(item)"
            style="margin-bottom: 20px"
          >
            <img v-if="item.img" class="itemImg" :src="item.img" alt="" />
            <p class="itemDetails">
              <span class="itemDetailsTitle spanLindFeed twoEllipsis">{{
                item.title
              }}</span>
              <span class="itemDetailsDesc">{{
                item.addTime.slice(0, 10)
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryAllArticleid } from "@/api/home.js";
import { queryAllArticle } from "@/api/home";
export default {
  data() {
    return {
      user: "",
      newsList: [],
    };
  },
  created() {
    // 当前页
    queryAllArticleid(this.$route.params.id).then((res) => {
      this.user = res.data;
    });
    queryAllArticle("", 4, 1).then((res) => {
      this.newsList = res.rows;
    });
  },
  methods: {
    // 查看单个新闻资讯详情按钮
    allnewxq(index) {
      this.$router.push({ name: "新闻资讯详情", params: { id: index.id } });
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  width: 100%;
  background: #f5f6fa;
  // 顶部面包屑
  /deep/ .el-breadcrumb {
    width: auto;
    margin: 0px 0px 10px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    line-height: 14px;
    cursor: pointer;
    .el-breadcrumb__item {
      .el-breadcrumb__inner {
        color: #666666 !important;
      }
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      color: #333 !important;
    }
  }

  // 新闻列表 内容
  .wrapsCen {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // 左侧白块
    .wrapsCenL {
      width: 860px;
      height: 100%;
      padding: 20px;
      // 内容
      .wrapsCenLs {
        width: 100%;
        margin: auto;
        min-height: 80%;
        background: #ffffff;
        border-bottom: 1px solid #eeeeee;
        border-radius: 8px;
        padding: 40px 20px;
        .title {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .laiyuan {
          display: flex;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          border-bottom: 1px solid #eeeeee;

          .time {
            margin: 16px 29px 19px 0px;
          }
          .time {
            color: #999999;
          }
        }
        img {
          width: 100%;
          height: 300px;
        }
      }
      // 上一篇、下一篇
      .wrapsCenLBtm {
        width: auto;
        height: 64px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .nextText {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          cursor: pointer;
        }
      }
    }
    // 右侧白块
    .wrapsCenR {
      margin-bottom: 20px;
      width: 340px;
      margin-left: 20px;
      // height: 650px;
      background: #ffffff;
      margin-top: 54px;
      border-radius: 8px;
      // 单个模块
      .wrapsCenRs {
        width: 85%;
        // height: 185px;
        margin: auto;
        margin-top: 25px;
        padding-bottom: 20px;
        // 标题
        .title {
          width: auto;
          height: auto;
          // margin-bottom: 20px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #171920;
        }
        // 内容
        div {
          width: 100%;
          // margin-bottom: 20px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #474747;
          cursor: pointer;
        }
      }
    }
  }
}
/deep/ .rice {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  margin: revert !important;
  p {
    line-height: 2 !important;
    padding: inherit !important;
    margin: revert !important;
  }
}
.icon {
  margin-left: 10%;
  padding-bottom: 20px;
  width: 18px;
}
.itemImg {
  min-width: 120px;
  width: 120px;
  height: 80px;
  border-radius: 4px 4px 4px 4px;
}
.itemDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
}
.itemDetailsTitle {
  font-size: 14px;
  color: #333333;
}
.itemDetailsDesc {
  font-size: 14px;
  color: #999;
}
.flexB {
  display: flex;
  // justify-content: space-between;
}
</style>
